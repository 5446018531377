<template>
    <div class="pair-buttons">
        <div class="button" :class="active == 1? 'active' : ''" @click="switchButton(1)">{{button1}} <span v-if="count1">({{count1}})</span></div>
        <div class="button" :class="active == 2? 'active' : ''" @click="switchButton(2)">{{button2}} <span v-if="count2">({{count2}})</span></div>
        <div v-if="button3" class="button" :class="active == 3? 'active' : ''" @click="switchButton(3)">{{button3}} <span v-if="count3">({{count3}})</span></div>
    </div>
</template>

<script>
    export default {
        name: "PairButtons",
        data() {
            return {
                active: 1
            }
        },
        props: {
            button1: {
                type: String,
                default: ''
            },
            count1: {
                type: Number,
                default: null
            },
            button2: {
                type: String,
                default: ''
            },
            count2: {
                type: Number,
                default: null
            },
            button3: {
                type: String,
                default: ''
            },
            count3: {
                type: Number,
                default: null
            },
        },
        methods:{
            switchButton(element){
                this.active = element
                this.$emit('click-'+ element)
            },
            // clickOne(){
            //     this.active = 1
            //     this.$emit('clickOne')
            // },
            // clickTwo(){
            //     this.active = 2
            //     this.$emit('clickTwo')
            // }
        }
    }
</script>

<style scoped>

</style>